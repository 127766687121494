@use "colors";
@use "screen";

.TitleStack {
  --vh: 70vh;
  font-family: "Anton", "Open Sans", sans-serif;
  text-transform: uppercase;
  letter-spacing: -0.02em;

  @media screen and (max-width: screen.$mobile) {
    // --arrow-display: none;
    --vh: 30vh;
  }

  > div {
    line-height: 1em;
  }

  &__Lines {
    // Two rows
    &2 {
      > div {
        &:nth-child(1) {
          font-size: calc(var(--vh) * 0.15);
        }

        &:nth-child(2) {
          font-size: calc(var(--vh) * 0.2);
        }
      }
    }

    // Three rows
    &3 {
      > div {
        &:nth-child(1) {
          font-size: calc(var(--vh) * 0.1);
        }

        &:nth-child(2) {
          font-size: calc(var(--vh) * 0.15);
        }

        &:nth-child(3) {
          font-size: calc(var(--vh) * 0.2);
        }
      }
    }
  }
}
