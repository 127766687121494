@use "colors";
@use "screen";

.ThumbnailCard {
  --height: 360px;

  @media screen and (max-width: screen.$mobile) {
    --height: 220px;
  }

  display: flex;
  position: relative;
  justify-content: center;
  padding: 0;
  margin: 0;
  align-items: center;
  cursor: pointer;
  flex: 0 0 auto;

  &__Image {
    height: var(--height);
    width: auto;

    img {
      height: var(--height);
      width: auto;
    }
  }

  &__Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background: rgba(colors.$purple, 0.5);
    transition: all 0.2s ease;

    svg path {
      fill: colors.$white;
    }
  }

  &__Progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: rgba(colors.$white, 0.5);

    > div {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: colors.$pink;
    }
  }

  &:hover & {
    &__Overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}
