@use "colors";
@use "screen";

.NavDrawer {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  gap: 10px;
  flex-shrink: 0;
  padding: var(--nav-height, 70px) 0 0 0;
  background: rgba(colors.$black, 0.9);
  backdrop-filter: blur(10px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  z-index: 99;

  &__Account {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    svg path {
      fill: colors.$white;
    }
  }

  &__Buttons {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 0;
    width: 100%;
    gap: 10px;
    padding: 10px 20px;

    > .Button {
      flex: 1 1 auto;
    }
  }

  &--open {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
}
