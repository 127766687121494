$white: #ffffff;
$purple: #6c47ff;
$black: #18141d;
$error: #ff3a69;
$pink: #ff3a69;

// Equal to opacity of $dark, used for all grey stuff
$grey5: #f3f3f4;
$grey10: #e8e7e8;
$grey20: #d1d0d2;
