@use "colors";
@use "screen";

.SeriesCard {
  --aspect-ratio: auto;
  --item-content-flex: 0;
  --vh: 70vh;

  @media screen and (max-width: screen.$mobile) {
    --aspect-ratio: 1/1;
    --item-content-flex: 0;
  }

  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  gap: 20px;
  background-position: center center;
  background-size: cover;
  transition: all 0.2s ease;
  aspect-ratio: var(--aspect-ratio);

  &--header {
    height: var(--vh);
    min-height: 700px;
    padding: calc(var(--nav-height, 70px) + 20px) 40px 40px 40px;
  }

  &--fill {
    height: 100%;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to left,
      rgba(colors.$black, 0) 0%,
      rgba(colors.$black, 0.5) 100%
    );
    z-index: 1;
  }

  &__Top {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 1 auto;
    z-index: 2;
  }

  &__Bottom {
    display: flex;
    flex: var(--item-content-flex);
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-end;
    z-index: 2;
  }

  &--header & &__Bottom {
    flex: 0 1 auto;
  }

  &__Title {
    display: flex;
    color: colors.$white;
    justify-content: flex-start;
    align-items: flex-end;
    flex: 1 1 auto;
    // Only use this if you have a lot of bright images and need to separate
    // text from the image.
    // text-shadow: 2px 2px 0px rgba(colors.$black, 0.5);

    &--inline {
      font-size: 40px;
      font-weight: bold;
      text-transform: uppercase;
      font-family: "Anton", "Open Sans", sans-serif;
      letter-spacing: 0;
    }
  }

  &__Buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  // Hug the first button
  &--fill & {
    &__Buttons {
      flex: 0 1 auto;
      .Button {
        &:nth-child(1) {
          flex: 0;
        }
      }
    }
  }

  &__Tagline {
    display: flex;
    color: colors.$white;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.02em;
  }

  &__Count {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 0 1 auto;
    color: colors.$white;
    text-transform: uppercase;
    letter-spacing: 0em;

    span {
      color: colors.$white;
      font-weight: bold;
    }

    div {
      color: rgba(colors.$white, 0.7);
    }
  }
}
