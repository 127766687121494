@use "../../components/styles/colors";
@use "../../components/styles/screen";

.Give {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 70px;
}
