@use "../../components/styles/colors";
@use "../../components/styles/screen";

.Auth {
  --form-max-width: 500px;

  @media screen and (max-width: screen.$mobile) {
    --form-max-width: 100%;
  }

  display: flex;
  padding: 40px;
  padding-top: 110px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: colors.$white;
  height: 100%;
  width: 100%;
  gap: 20px;
  max-width: 640px;
  font-family: "Open Sans", sans-serif;
  flex: 1 0 auto;

  &__Form {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    max-width: var(--form-max-width);
    flex: 1 0 auto;
  }

  &__Fields {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    flex-basis: auto;
    gap: 20px;
    width: 100%;
  }

  &__Buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
