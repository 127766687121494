@use "colors";
@use "screen";

.NavBar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100vw;
  z-index: 998;

  &__Content {
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 20px;
    background: rgba(colors.$black, 0.8);
    backdrop-filter: blur(10px);
    justify-content: space-between;
    align-items: center;
    z-index: 999;
  }

  &__Menu {
    display: flex;
    cursor: pointer;

    svg path {
      fill: colors.$white;
    }
  }

  &__Search {
    display: flex;
    cursor: pointer;

    svg path {
      fill: colors.$white;
    }
  }

  &__Logo {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg path {
      transition: all 0.2s ease;
      fill: colors.$white;
    }

    &:hover {
      svg path {
        fill: colors.$purple;
      }
    }
  }

  &--menu-open & {
    &__Menu {
      svg path {
        fill: colors.$purple;
      }
    }
  }

  &--search-open & {
    &__Search {
      svg path {
        fill: colors.$purple;
      }
    }
  }

  // Desktop styles
  &--desktop {
    flex-direction: row;
    background: rgba(colors.$black, 0.8);
    backdrop-filter: blur(10px);
    padding: 20px;
    justify-content: center;
  }

  &--desktop & {
    &__Logo {
      padding: 0;
      flex: 0 1 auto;

      svg path {
        transition: all 0.2s ease;
        fill: colors.$white;
      }

      &:hover {
        svg path {
          fill: colors.$purple;
        }
      }
    }

    &__Left {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex: 1 1 auto;
      z-index: 999;

      svg path {
        fill: colors.$white;
      }
    }

    &__Right {
      display: flex;
      flex-direction: row;
      gap: 0px;
      flex: 0;
      z-index: 999;
    }

    &__Links {
      display: flex;
      flex-direction: row;
      gap: 0px;
      padding: 0px;
    }

    &__Link {
      display: flex;
      position: relative;
      box-sizing: border-box;
      user-select: none;
      color: rgba(colors.$white, 0.7);
      border: 4px solid transparent;
      padding: 6px 10px;
      font-size: 14px;
      line-height: 10px;
      font-weight: bold;
      letter-spacing: 0.02em;
      transition: all 0.2s ease;
      white-space: nowrap;
      text-transform: uppercase;
      cursor: pointer;

      &:hover:not(&--active) {
        border-color: colors.$white;
        color: colors.$white;
      }

      &--active {
        background: colors.$white;
        color: colors.$black;

        svg path {
          fill: colors.$black !important;
        }
      }

      &--icon {
        padding-left: 40px;

        .Icon {
          position: absolute;
          top: 3px;
          left: 10px;
          svg path {
            transition: all 0.2s ease;
            fill: rgba(colors.$white, 0.7);
          }
        }
      }
    }
  }
}
