@use "colors";
@use "screen";

.Modal {
  --padding: 40px;
  --width: 400px;
  --height: auto;

  @media screen and (max-width: screen.$mobile) {
    --padding: 40px;
    --width: 100%;
    --height: 100%;
  }

  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(16, 15, 18, 0.5);
  backdrop-filter: blur(20px);
  z-index: 1002;
  opacity: 0;
  transition: opacity;
  transition-delay: 0s;
  transition-duration: 0.42s;

  &--open {
    opacity: 1;
  }

  &__Box {
    width: var(--width);
    height: var(--height);

    display: flex;
    padding: var(--padding);
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    gap: 40px;
    background: colors.$white;
  }

  &__Bottom {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 40px;
    width: 100%;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    width: 100%;
  }

  &__Top {
    display: flex;
    padding: 0 var(--padding) 0 0;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -0.02em;
    line-height: 1.4em;
  }

  &__Buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__Close {
    display: flex;
    position: absolute;
    top: var(--padding);
    right: var(--padding);
    cursor: pointer;

    svg path {
      fill: colors.$black;
    }

    &:hover {
      svg path {
        fill: colors.$purple;
      }
    }
  }
}
