@use "colors";

/* Animation styles */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dot-animation {
  0% {
    background: rgba(colors.$black, 0.1);
    transform: scale(1);
  }
  50% {
    background: rgba(colors.$black, 1);
    transform: scale(1.2);
  }
  100% {
    background: rgba(colors.$black, 0.1);
    transform: scale(1);
  }
}

.Loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(colors.$white, 0.8);
  border-radius: inherit;
  overflow: hidden;
  z-index: 99;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  &--color-dark {
    background: rgba(colors.$black, 0.8);
  }

  &--color-dark & {
    &__Spinner {
      border-color: rgba(colors.$white, 0.5) rgba(colors.$white, 0.5)
        transparent transparent;
    }
  }

  &__Dots {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0;
    justify-content: center;
    align-items: center;

    // Small dots
    &--small {
      gap: 4px;
      span {
        width: 4px !important;
        height: 4px !important;
      }
    }

    span {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(1);
      background: rgba(colors.$black, 0.1);
      animation: 1s dot-animation linear infinite;
      &:nth-child(1) {
        animation-delay: 0.1s;
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.3s;
      }
    }
  }

  &__Spinner {
    width: 20px;
    height: 20px;
    border-color: rgba(colors.$black, 0.5) rgba(colors.$black, 0.5) transparent
      transparent;
    animation: spinner 1s linear infinite;
    border-style: solid;
    border-width: 4px;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
  }
}
