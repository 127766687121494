@use "colors";

.Button {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 10px;
  background: colors.$white;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 10px;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  width: 100%;
  letter-spacing: -0.02em;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;

  &--size-sm {
    height: 24px;
  }

  &--size-md {
    height: 30px;
  }

  &--size-lg {
    height: 40px;
  }

  &--size-xl {
    height: 50px;
  }

  // Icon mode sizes
  &--icon#{&}--size-sm {
    width: 24px;
    height: 24px;
    padding: 0;
    flex: 0 0 24px;
  }
  &--icon#{&}--size-md {
    width: 30px;
    height: 30px;
    padding: 0;
    flex: 0 0 30px;
  }
  &--icon#{&}--size-lg {
    width: 40px;
    height: 40px;
    padding: 0;
    flex: 0 0 40px;
  }
  &--icon#{&}--size-xl {
    width: 50px;
    height: 50px;
    padding: 0;
    flex: 0 0 50px;
  }

  // Standard white icon buttons hovering purple
  &--icon {
    svg path {
      fill: colors.$black;
      transition: all 0.2s ease;
    }

    &:hover {
      background: colors.$purple;

      svg path {
        fill: colors.$white;
      }
    }

    // Grey variant of the icon button
    &-grey {
      background: colors.$grey10;

      svg path {
        fill: colors.$black;
      }

      &:hover {
        background: colors.$black;

        svg path {
          fill: colors.$white;
        }
      }
    }
  }

  &--transparent {
    background: transparent !important;
  }

  // Disable while loading
  &--loading {
    cursor: not-allowed;
    pointer-events: none;
  }

  &--purple {
    color: colors.$white;
    background: colors.$purple;

    svg path {
      fill: colors.$white;
      transition: all 0.2s ease;
    }

    &:hover {
      color: colors.$white;
      background: colors.$black;

      svg path {
        fill: colors.$white;
      }
    }
  }

  &--primary {
    color: colors.$black;
    background: colors.$white;

    svg path {
      fill: colors.$black;
      transition: all 0.2s ease;
    }

    &:hover {
      color: colors.$white;
      background: colors.$purple;

      svg path {
        fill: colors.$white;
      }
    }
  }

  &--secondary {
    color: colors.$white;
    background: rgba(colors.$white, 0.2);

    svg path {
      fill: colors.$white;
      transition: all 0.2s ease;
    }

    &:hover {
      color: colors.$white;
      background: colors.$purple;

      svg path {
        fill: colors.$white;
      }
    }
  }

  &--grey {
    color: colors.$black;
    background: colors.$grey10;

    svg path {
      fill: colors.$black;
      transition: all 0.2s ease;
    }

    &:hover {
      color: colors.$white;
      background: colors.$black;

      svg path {
        fill: colors.$white;
      }
    }
  }

  &--border {
    border: 4px solid colors.$black;
    background: colors.$white;

    svg path {
      fill: colors.$black;
    }

    &:hover {
      color: colors.$purple;
      border: 4px solid colors.$purple;

      svg path {
        fill: colors.$purple;
      }
    }
  }

  /**
  Disabled styles
  */
  &--disabled {
    cursor: not-allowed !important;
    pointer-events: none !important;
    opacity: 0.5 !important;
  }

  // Spinner on the button
  .DefaultSpinner {
    position: absolute;
  }
}
