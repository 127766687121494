@use "colors";
@use "screen";

.ShareModal {
  display: flex;
  position: fixed;
  top: var(--nav-height, 70px);
  left: 0;
  width: 100%;
  height: calc(100% - var(--nav-height, 70px));
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: colors.$white;
  transition: all 0.5s ease;
  z-index: -1;

  // Invisible until visible
  visibility: hidden;
  opacity: 0;

  &--open {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }

  &__Buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }
}
