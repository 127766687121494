@use "../../components/styles/colors";

.ScrollView {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &__Bar {
    cursor: pointer;
    transition: opacity 0.5s ease, background-color 0.5s ease;
    position: absolute;
    right: 0px;
    border-radius: 3px;
    width: 5px;
    background: rgba(colors.$white, 0.2);
    opacity: 0;
    height: 0;
    z-index: 99;

    &:hover {
      background: rgba(colors.$white, 0.4);
    }
  }

  &__Content {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &--horizontal {
    height: 5px;
    right: inherit;
  }

  &--horizontal-content {
    flex-direction: row;
  }
}

.ScrollView--light {
  & .ScrollView {
    &__Bar {
      background: rgba(colors.$white, 0.2);

      &:hover {
        background: rgba(colors.$white, 0.4);
      }
    }
  }
}
