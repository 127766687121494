@use "../../components/styles/colors";
@use "../../components/styles/screen";

.VideoSeries {
  --first-button-flex: 0;

  @media screen and (max-width: screen.$mobile) {
    --first-button-flex: 1;
  }

  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  &__Buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
    width: auto;
    flex: 0 1 auto;

    > div {
      flex: 0;

      &:first-child {
        flex: var(--first-button-flex);
      }
    }
  }
}
